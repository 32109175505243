<template>
    <div class="grid">
        <div class="col-12">

            <div class="card">
                <div class="text-800 mb-1 field">
            <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                  <AppInfoManual nomeTelaDoManual="equipament-form" />
                  <h3 class="ml-1">Equipamentos</h3>
             </div>
            </div>
              <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                  <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                      <div style="display: flex; align-items: center; justify-content: space-between">
                        <Chips v-model="filter" style="width: 80%" placeholder="Pesquisar" @add="filtrarEquipamentos" @remove="load" />
                      </div>
                  </div>
                  <div class="mt-2 ml-auto md:mt-0">
                      <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openBasic" />
                  </div>

                  <!-- // modal novo Equipamento -->
                  <Dialog header="Cadastro" v-model:visible="displayBasic" :style="{width: '50vw'}" ariaCloseLabel>
                   <TabView :scrollable="true" v-model:activeIndex="active">

                      <!-- profissional / adicionar profssional -->
                      <TabPanel header="Cadastro" @click="active = 0">
                          <div style="display: flex; margin-bottom: 20px;">
                              <div style="display: flex; flex-direction: column;">
                                <div style="max-width: 320px; min-width: 320px;">
                                    <label for="name" class="mr-2">Nome </label>
                                    <InputText id="name" style="max-width: 180px; min-width: 180px;" required="true" v-model.trim="form.name" autofocus autocomplete="off" :class="{'p-invalid': submitted && !form.name}" />
                                </div>
                                <div style="max-width: 320px; min-width: 320px; margin-top: 15px">
                                   <label for="valor" class="mr-3">Valor </label>
                                    <InputNumber mode="currency" currency="BRL" locale="pt-BR" id="valor" style="max-width: 180px; min-width: 180px;" v-model="form.value" required="true" autofocus autocomplete="off" :class="{'p-invalid': submitted && !form.value}" />
                                </div>
                                <div class="field-checkbox" style="display: flex; align-items: center; margin-top: 20px; justify-content: space-between;">
                                  <div style="display: flex; align-items: center; justify-content: space-between;">
                                    <Checkbox v-model="form.isLeased" :binary="true" />
                                    <div style="margin-left: 10px;">
                                      <label for="binary">Locado</label>
                                    </div>
                                  </div>
                              </div>
                              <div style="display: flex; align-items: center; margin-top: 10px; max-width: 320px; min-width: 320px;">
                                <Checkbox v-model="form.isShare" :binary="true" />
                                <div style="margin-left: 10px;">
                                  <label for="binary">Disponibilizar equipamentos para terceiros</label>
                                </div>
                              </div>
                            </div>
                            </div>
                      </TabPanel>

                      <!-- equipamentos / Controle de vencimentos -->
                      <TabPanel :disabled="true" :visible="false" header="Controle de vencimentos" @click="active = 2">
                        <div class="grid">
                            <div class="col-12">
                              <div style="display: flex; max-width: 330px; min-width: 330px; align-items: center; justify-content: space-between;">
                                    <label>Data da última revisão</label>
                                    <InputMask id="CNHDueDate" mask="99/99/9999" placeholder="00/00/0000" required="true" v-model.trim="form.lastReviewDate" autofocus autocomplete="off" />
                              </div>
                              <div style="display: flex; max-width: 330px; min-width: 330px; align-items: center; justify-content: space-between; margin-top: 10px;">
                                    <label>Data da próxima revisão</label>
                                    <InputMask id="CNHDueDate" mask="99/99/9999" placeholder="00/00/0000" required="true" v-model.trim="form.nextReviewDate" autofocus autocomplete="off" />
                              </div>
                              <div style="display: flex; max-width: 330px; min-width: 330px; align-items: center; justify-content: space-between; margin-top: 10px;">
                                    <label>Valor da revisão</label>
                                    <InputText id="name" required="true" v-model.trim="form.nextReviewValue" autofocus autocomplete="off" />
                              </div>
                            </div>
                        </div>
                      </TabPanel>

                      <!-- equipamentos / Itens verificados -->
                      <TabPanel :disabled="true" :visible="false" header="Itens verificados" @click="active = 3">
                        <div class="grid">
                            <div class="col-12">
                              <div style="display: flex; max-width: 330px; min-width: 330px; align-items: center; justify-content: space-between;">
                                    <label>Nome</label>
                                    <InputText id="name" required="true" v-model.trim="verifiedText" autofocus autocomplete="off" />
                              </div>
                              <div style="display: flex; max-width: 330px; min-width: 330px; align-items: center; justify-content: space-between; margin-top: 10px;">
                                    <label>Periodicidade</label>
                                    <InputText id="name" required="true" v-model.trim="frequencyText" autofocus autocomplete="off" />
                              </div>
                              <hr/>
                              <div style="margin-top: 10px;" class="mt-2 ml-auto md:mt-0">
                                <Button @click="addVerified" label="Adicionar" icon="pi pi-plus" class="p-button p-button-text mr-2" />
                              </div>
                               <div class="col-12 p-fluid painel painel-2 ">
                                      <DataTable
                                        dataKey="id"
                                        :value="listCheckedItems"
                                        :row-hover="true"
                                        responsiveLayout="scroll"
                                        breakpoint="640px"
                                        :paginator="true"
                                        :rows="10"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        :rowsPerPageOptions="[10, 20, 50]"
                                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                        @row-click="openModalInfoVerified($event)"
                                        style="cursor: pointer"
                                      >
                                        <template #empty> Nenhum registro encontrado. </template>

                                        <template #loading> Carregando registros. Aguarde ... </template>
                                        <Column field="listCheckedItems" header="Nome">
                                            <template #body="slotProps">
                                              {{slotProps.data?.name || '-'}}
                                            </template>
                                        </Column>
                                        <Column field="listCheckedItems" header="Periodicidade">
                                            <template #body="slotProps">
                                              {{slotProps.data?.period || '-'}}
                                            </template>
                                        </Column>
                                        <Column header="">
                                          <template #body="slotProps">
                                            <Button icon="pi pi-ban" @click="deleteVerifiel(slotProps.data?.id)" style="height: 30px; width: 30px;" />
                                          </template>
                                        </Column>
                                      </DataTable>
                                  </div>
                            </div>
                        </div>
                      </TabPanel>
                   </TabView>

                   <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="closeBasic" />
                    </div>

                </Dialog>
              </div>

              <Toast/>

              <!-- listagem de equipamentos -->
              <div class="col-12 p-fluid painel painel-2 ">
                  <DataTable
                    dataKey="id"
                    :value="records"
                    :row-hover="true"
                    responsiveLayout="scroll"
                    breakpoint="640px"
                    :rows="10"
                    style="cursor: pointer"
                  >
                    <template #empty> Nenhum registro encontrado. </template>

                    <template #loading> Carregando registros. Aguarde ... </template>
                    <Column field="id" header="Equipamento" :sortable=" true" sortField="id">
                      <template #body="slotProps">
                        <label>
                          {{slotProps.data?.name || ''}}
                        </label>
                      </template>
                    </Column>
                      <Column field="value" header="Valor" :sortable=" true" sortField="value">
                        <template #body="slotProps">
                          <label>
                            {{ 'R$' + slotProps.data?.value }}
                          </label>
                        </template>
                    </Column>
                    <Column field="isLeased" header="Locado" :sortable=" true" sortField="isLeased">
                      <template #body="slotProps">
                        <label>
                          {{slotProps.data?.isLeased ? 'Sim' : 'Não'}}
                        </label>
                      </template>
                    </Column>
                    <Column field="isShare" header="Disponibilizar para terceiros" :sortable=" true" sortField="isShare">
                      <template #body="slotProps">
                        <label>
                          {{slotProps.data?.isShare ? 'Sim' : 'Não'}}
                        </label>
                      </template>
                    </Column>
                    <Column bodyClass="text-right" headerStyle="width: 10rem;">
                        <template #body="slotProps">
                          <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                        </template>
                    </Column>
                  </DataTable>
                  <Paginator
                    :rows="perPage"
                    :totalRecords="total"
                    @page="onPage($event)"
                    template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                    currentPageReportTemplate="Página {currentPage} de {totalPages}"
                  />
                  <Menu ref="menu" :model="actionItems" :popup="true" />
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SecurityService from "../../services/SecurityService";
    import { v4 as uuidv4 } from 'uuid';
    import { formatDropDawn } from '../../utils/Mask';
import AppInfoManual from "../../components/AppInfoManual.vue";

    export default {
    data() {
        return {
            actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    command: () => this.openModalEditEquip(this.actionRecord)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.deleteEquipament(this.actionRecord)
                }
            ],
            form: {},
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 10,
            currentPerPage: null,
            displayBasic: false,
            active: 0,
            filters: null,
            formInfo: undefined,
            isShare: false,
            isLeased: false,
            submitted: false,
            filter: '',
            records: [],
            allEquipsDefault: [],
            timeout: null,
            loading: false,
            verifiedText: '',
            frequencyText: '',
            listCheckedItems: [],
            equipsDefault: [],
            splitAllEquip: [],
        };
    },
    watch: {
        page() {
            this.load();
        },
    },
    mounted() {
        this.$service = new SecurityService('/ferramentas/equipamentos');
        this.$serviceAll = new SecurityService('/ferramentas/equipamentos/todos');
        this.$serviceLogs = new SecurityService('/ferramentas/equipamentos/historico');
        this.load();
    },
    methods: {
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        // pesquisa
        filtrarEquipamentos(event) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(async () => {
                const verifyFilter = event.value || this.filter;
                const formatFilter = Array.isArray(verifyFilter) ? verifyFilter : [verifyFilter];
                const params = { filter: formatFilter };
                if (event.value) {
                    const { data } = await this.$serviceAll.findAll(null, params);
                    this.records = data?.items || [];
                    const getAllEquip = formatDropDawn(data?.items || []);
                    this.splitAllEquip = getAllEquip;
                }
                else {
                    const { data } = await this.$service.findAll(null, params);
                    this.records = data?.items || [];
                    this.total = data?.meta?.totalItems || 0;
                    this.totalPage = data?.meta?.totalPages || 0;
                }
            }, 500);
        },
        // abrir modal de criaçao/ediçao
        openBasic() {
            this.form = {};
            this.active = 0;
            this.formInfo = undefined;
            this.displayBasic = true;
            this.submitted = false;
            this.listCheckedItems = [];
        },
        closeBasic() {
            this.displayBasic = false;
        },
        addVerified() {
            if (this.verifiedText && this.frequencyText !== '') {
                this.listCheckedItems.push({
                    name: this.verifiedText,
                    period: this.frequencyText,
                    id: uuidv4(),
                });
                this.verifiedText = '',
                    this.frequencyText = '';
            }
        },
        deleteVerifiel(idVerifiel) {
            this.listCheckedItems.splice(idVerifiel, 1);
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        // criacçao de equipamentos
        async save() {
            if (this.formInfo) {
                this.saveEdit();
                return;
            }
            try {
                this.submitted = true;
                const body = {
                    name: this.form.name,
                    value: this.form.value,
                    isLeased: this.form.isLeased || false,
                    isShare: this.form.isShare || false,
                };
                const stringBody = JSON.stringify(body);
                await this.$service.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Equipamentos salvo com sucesso', life: 3000 });
                this.submitted = false;
                this.closeBasic();
                this.load();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o equipamento!', life: 3000 });
            }
        },
        // ediçao de equipamentos
        async openModalEditEquip(itemSelect) {
            const formatlastReviewDate = itemSelect?.lastReviewDate?.split('-');
            const formatNextReviewDate = itemSelect?.nextReviewDate?.split('-');
            this.form = {
                name: itemSelect?.name,
                value: itemSelect?.value,
                isLeased: itemSelect?.isLeased,
                isShare: itemSelect?.isShare,
                lastReviewDate: itemSelect?.lastReviewDate ? `${formatlastReviewDate[2]}/${formatlastReviewDate[1]}/${formatlastReviewDate[0]}` : null,
                nextReviewDate: itemSelect?.nextReviewDate ? `${formatNextReviewDate[2]}/${formatNextReviewDate[1]}/${formatNextReviewDate[0]}` : null,
                nextReviewValue: itemSelect?.nextReviewValue,
            };
            this.listCheckedItems = JSON.parse(itemSelect?.listCheckedItems) ? JSON.parse(itemSelect?.listCheckedItems) : this.listCheckedItems;
            this.formInfo = itemSelect;
            this.displayBasic = true;
        },
        // salvar ediçao
        async saveEdit() {
            try {
                this.submitted = true;
                const body = {
                    name: this.form.name,
                    isLeased: this.form.isLeased,
                    isShare: this.form.isShare,
                    value: this.form.value,
                };
                const stringBody = JSON.stringify(body);
                await this.$service.save(stringBody, this.formInfo?.id);
                this.$toast.add({ severity: 'success', summary: 'Equipamentos salvo com sucesso!', life: 3000 });
                this.submitted = false;
                this.form = {};
                this.closeBasic();
                this.load();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o equipamento!', life: 3000 });
            }
        },
        async deleteEquipament(infoEquipamentSelected) {
            try {
                await this.$service.remove(infoEquipamentSelected?.id);
                this.$toast.add({ severity: 'success', summary: 'Equipamento deletado com sucesso!', life: 3000 });
                this.load();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao deletar o Equipamento!', life: 3000 });
            }
        },
        async load() {
            this.loading = true;
            try {
                const { data } = await this.$service.findAll(null, {
                    limit: this.currentPerPage,
                    page: this.page
                });
                this.records = data?.items || [];
                this.total = data?.meta?.totalItems || 0;
                this.totalPage = data?.meta?.totalPages || 0;
                const allData = await this.$serviceAll.findAll(null, {});
                const getAllEquip = formatDropDawn(allData?.data?.items || []);
                this.splitAllEquip = getAllEquip;
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar Equipamentos!', life: 3000 });
            }
            this.loading = false;
        },
    },
    components: { AppInfoManual }
}
</script>
